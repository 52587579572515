import React, { useContext } from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import GalleryContainer from "../components/GalleryContainer";
import CollectionContainer from "../components/CollectionContainer";
import ParentRouteHandler from "../components/ParentRouteHandler";
import { StoreContext } from "../store";

const ExploreTheBroadPage = ({ location, data }) => {
  const { exploredArt } = useContext(StoreContext);

  const artworks = data.allNodeArtPages.edges.map(({ node }) => {
    const { path, drupal_id } = node;
    const { alias } = path;
    const exploreArtAlias = alias.replace("/art/", "/explore/");
    let explored = false;
    if (exploredArt && exploredArt[drupal_id]) {
      explored = true;
    }

    return {
      node: {
        ...node,
        explored,
        path: {
          ...path,
          alias: exploreArtAlias,
        },
      },
    };
  });

  return (
    <CollectionContainer
      render={({ dataForGalleryView }) => {
        return (
          <>
            <Seo title="Explore The Broad Collection" />
            {location.state &&
            location.state.originalPath &&
            location.state.originalPath === "home" ? (
              <ParentRouteHandler title="Home" path="/" />
            ) : (
              <ParentRouteHandler title="For Families" path="/for-families" />
            )}
            <div className="heading">
              <HeadingWithSub
                main={`Explore The Broad Collection - Fill in the Blanks`}
                subTitle={`For families`}
              />
            </div>

            <GalleryContainer data={dataForGalleryView(artworks)} />
          </>
        );
      }}
    />
  );
};

export default ExploreTheBroadPage;

export const query = graphql`
  query {
    allNodeArtPages(
      filter: {
        field_on_view: { eq: true }
        field_include_families_explore: { eq: true }
      }
      sort: { fields: [field_sort_order_weight], order: ASC }
    ) {
      edges {
        node {
          ...ArtPages
        }
      }
    }
  }
`;
