import React from "react";
import LinkToTour from "./LinkToTour";
import styled from "@emotion/styled";

const CollectionPageStyledContainer = styled.div`
  .heading {
    margin-bottom: 30px;
  }

  .toggle-wrap {
    margin-bottom: 20px;
  }
`;
const CollectionContainer = ({ render }) => {
  const dataForGalleryView = (artworks) =>
    artworks.map(({ node }) => {
      let image = null; // placeholder image fallback
      if (node.rel.image?.rel.media.localFile) {
        image = node.rel.image?.rel.media.localFile;
      }

      return {
        image,
        node,
      };
    });

  const renderList = (artworks, queryParam = null) => {
    return (
      <div>
        {artworks.map(({ node }, index) => {
          let image = null; // placeholder image fallback
          if (node.rel.image?.rel.media.localFile) {
            image = node.rel.image?.rel.media.localFile;
          }
          const link = queryParam
            ? `${node.path.alias}?${queryParam}`
            : node.path.alias;

          return (
            <LinkToTour
              key={node.path.alias}
              title={node.title}
              link={link}
              subtitle={node.artistName}
              image={image}
            />
          );
        })}
      </div>
    );
  };

  return (
    <CollectionPageStyledContainer>
      {render({ renderList, dataForGalleryView })}
    </CollectionPageStyledContainer>
  );
};

export default CollectionContainer;
